import React from "react";
import { graphql, Link } from "gatsby";
// import ReactMarkdown from "react-markdown"
// import Moment from "react-moment"
import Layout from "../components/layout";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, useMediaQuery, Box } from "@material-ui/core";
import { PICTURE_STRAPI_API_URL } from "../utils/constants";
import parse, { domToReact } from "html-react-parser";
export const query = graphql`
  query BlogQuery($id: String!) {
    strapiBlogs(slug: { eq: $id }) {
      strapiId
      title
      slug
      content
      description
      published_at
      image {
        publicURL
      }
      seoImage {
        publicURL
      }
      seoTitle
      seoDescription
      seoKeyword
      seoImageAlternativeText
      imageAlternativeText
    }
  }
`;

const Blog = ({ data }) => {

  const blog = data?.strapiBlogs;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));

  // function Image(props) {
  //   return <img {...props} style={{ maxWidth: "100%" }} alt="thumbnail" />
  // }

  const getId = (url) => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return "error";
    }
  };

  const seoData = {
    // title: data?.strapiBlogs?.seoTitle,
    title: blog?.title,
    url: `blogs/${blog?.slug}`,
    description: data?.strapiBlogs?.seoDescription,
    image: data?.strapiBlogs?.seoImage?.publicURL,
    keyword: data?.strapiBlogs?.seoKeyword,
  };

  return (
    <Layout seoData={seoData}>
      <Grid container className={classes.mainContainer} >
        <div
          className={classes.container}
          title={blog?.title}
          style={{ backgroundImage: `url(${blog?.image?.publicURL})` }}
          alt={blog?.imageAlternativeText}
        ></div>

        <Grid item xs={12} className={classes.innerContainer}>
          <Typography
            className={classes.heading}
            style={{
              fontSize: isXS ? "13px" : isMobile && "36px",
              //    marginTop: "15%",
            }}
          >
            {blog?.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {blog?.description && (
            <Typography
              className={classes.descHeading}
              style={{ fontSize: isXS ? "12px" : isMobile && "20px" }}
            >
              {`"${blog?.description}"`}
            </Typography>
          )}
        </Grid>

        <Box
          className={classes.description}
          style={{ fontSize: isXS ? "12px" : isMobile && "20px" }}
        >
          {/* <ReactMarkdown
          source={blog.content}
          renderers={{ image: Image }}
          transformImageUri={uri =>
            uri.startsWith("http") ? uri : `${PICTURE_STRAPI_API_URL}${uri}`
          }
        /> */}

          <div className="ck-content">
            {blog?.content &&
              parse(blog?.content, {
                replace: (domNode) => {
                  if (domNode.name && domNode.name === "img") {
                    return React.createElement("img", {
                      src: `${PICTURE_STRAPI_API_URL}${domNode?.attribs?.src}`,
                      alt: `${domNode?.attribs?.alt}`,
                      style: { paddingLeft: "5%" },
                    });
                  }
                  if (
                    domNode.name &&
                    domNode.name === "a" &&
                    !domNode?.attribs?.href?.includes("mailto:")
                  ) {
                    return (
                      <Link
                        to={
                          domNode?.attribs?.href?.includes("https://")
                            ? domNode?.attribs?.href
                            : `https://${domNode?.attribs?.href}`
                        }
                        target="_blank"
                      >
                        {domToReact(domNode?.children, domNode?.options)}
                      </Link>
                    );
                  }
                  if (domNode.name && domNode.name === "oembed") {
                    let videoId = getId(domNode?.attribs?.url);

                    return (
                      <Grid
                        container
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: isMobile ? "200px" : "500px",
                        }}
                      >
                        <iframe
                          width="90%"
                          height={"100%"}
                          title="title"
                          src={`//www.youtube.com/embed/${videoId}`}
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      </Grid>
                    );
                  }
                },
              })}
          </div>
        </Box>

        {/* <Grid container justify="flex-end" style={{ marginTop: "5%" }}>
        <Box className={classes.date}>
          <b>Published At: </b>
          <Moment format="MMMM DD, YYYY">{blog.published_at}</Moment>
        </Box>
      </Grid> */}
      </Grid>
    </Layout>
  );
};

export default Blog;

const useStyles = makeStyles((theme) => ({
  container: {
    // minHeight: "360px",
    // width: "100%",
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    background: "rgba(0,0,0,0.2)",
    width: "100%",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: 0,
    position: "relative",
    paddingTop: "33.33%",
    paddingBottom:"1.5em",
  },
  mainContainer: {
    paddingTop: "100px",
    width: "80%",
    margin: "auto",
  },

  innerContainer: {
    // minHeight: "360px",
    // width: "100%",
    // background: "rgba(0,0,0,0.2)",
    paddingBottom: "10px",
    paddingTop:"10px",
    display: "flex",
   // alignItems: "center",

    // background: "rgba(242,135,5,0.46)",
  },
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "45px",
    lineHeight: "64px",
    color: "#000",
   // textAlign: "center",
  },

  descHeading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
   // textAlign: "center",
    color: "#3D3D3D",
    paddingTop: "15px",
  },
  description: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    // fontWeight: 500,
    fontSize: "20px",
    // lineHeight: "151.69%",
    // letterSpacing: "0.045em",
    paddingTop: "14px",
    textAlign: "justify",
  },
  date: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "151.69%",
    letterSpacing: "0.045em",
    marginRight: "20px",
    marginBottom: "20px",
  },
}));
